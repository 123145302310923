<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" :rules="rules" label-width="180px">
      <el-form-item label="医生团队名称:" prop="teamName">
        <el-input
          v-model="form.teamName"
          v-check-permission
          placeholder="请输入医生团队名称"
          style="width: 40%"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="医生团队编码:" prop="teamCode">
        <el-input
          v-model="form.teamCode"
          v-check-permission
          placeholder="请输入医生团队编码"
          :disabled="isEditor"
          style="width: 40%"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="医生团队介绍：" prop="introduce">
        <el-input
          v-model="form.introduce"
          placeholder="请输入简介"
          style="width: 40%"
          type="textarea"
          maxlength="500"
          show-word-limit
          :rows="10"
        />
      </el-form-item>
      <!-- <el-form-item
        label="团队负责人:"
      >
        <el-input
          v-model="form.name"
          placeholder="请输入团队负责人"
          style="width: 40%;"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item
        label="团队负责人手机号:"
        prop="mobile"
      >
        <el-input
          v-model="form.mobile"
          placeholder="请输入医生负责人手机号"
          style="width: 40%;"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item
        label="团队负责人邮箱:"
        prop="email"
      >
        <el-input
          v-model="form.email"
          placeholder="请输入医生负责人邮箱"
          style="width: 40%;"
          maxlength="50"
        />
      </el-form-item> -->
      <el-form-item>
        <template>
          <el-button type="primary" @click="onSubmit()"> 保存 </el-button>
          <el-button type="primary" @click="$router.back()"> 返回 </el-button>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { nameVerification, checkPhone } from '@/utils/verificationRule.js';
export default {
  name: 'Details',
  data() {
    return {
      isEditor: Number(this.$route.query.type) === 1,
      selectId: '', // 修改团队的id
      signobj: {}, // 获取图片签名数据
      id: sessionStorage.id,
      form: {
        teamName: '',
        teamCode: '',
        introduce: '',
        mobile: '',
        email: '',
      },
      rules: {
        teamCode: [
          { required: true, validator: nameVerification, trigger: 'blur' },
        ],
        teamName: [
          { required: true, message: '请输入医生团队名称', trigger: 'blur' },
        ],
        introduce: [
          { required: true, message: '请输入团队简介', trigger: 'blur' },
        ],
        mobile: [{ required: false, validator: checkPhone, trigger: 'blur' }],
        email: [
          { required: false, trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  mounted() {
    console.log(
      Number(this.$route.query.type) === 1,
      'this.$route.query',
      this.isEditor
    );
    this.getDetail();
  },
  methods: {
    ...mapActions('doctorTeam', ['edit']),
    // // 手机号码校验
    // mobileRule(event) {
    //   var reg = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/;
    //   if(!reg.test(event)) {
    //     // this.$message.error('输入的电话号码格式不对');
    //     this.form.mobile = '';
    //   }
    // },
    // 获取详情数据
    getDetail() {
      if (this.isEditor) {
        const doctorTeamDetail = this.$route.query.teamDetail;
        console.log(doctorTeamDetail, 'ppppppppp');
        this.form = {
          teamName: doctorTeamDetail.teamName,
          teamCode: doctorTeamDetail.teamCode,
          introduce: doctorTeamDetail.introduce,
        };
        this.selectId = doctorTeamDetail.id;
      }
    },
    // 新增和编辑时保存
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const param = {
            ...JSON.parse(JSON.stringify(this.form)),
          };
          if (this.isEditor) {
            param.id = this.selectId;
          }
          this.edit(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '保存团队成功',
              });
              this.$router.go(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 40%;
  }
}
// .timedemo span {
//   font-weight: 600;
//   color: red;
//   border: 2px solid red !important;
// }
</style>
